import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import {
  WarriorCardDataType,
  TokenCardDataType
} from '../../../../types/types';
import deckBackgroundImage from '../../../../assets/Images/backcard.png';
import { CardTypeEnum } from '../../../../types/enums';
import './style.css';

type HandAndDeckProps = {
  usersHand: (WarriorCardDataType | TokenCardDataType)[];
  usersDeckCount: number;
  selectedCard: WarriorCardDataType | 'noCard' | null;
  setSelectedCard: (card: WarriorCardDataType | 'noCard' | null) => void;
  selectedTokenCard: TokenCardDataType | 'noCard' | null;
  setSelectedTokenCard: (card: TokenCardDataType | 'noCard' | null) => void;
  disableCardSelection: boolean;
  disableCardHoverInfo: boolean;
  updatedUserHand: (WarriorCardDataType | TokenCardDataType)[] | null;
  usersGraveyard: (WarriorCardDataType | TokenCardDataType)[];
  updatedUserDeckCount: number | null;
};

export const HandAndDeck = ({
  usersHand,
  usersDeckCount,
  selectedCard,
  setSelectedCard,
  selectedTokenCard,
  setSelectedTokenCard,
  disableCardSelection,
  disableCardHoverInfo,
  updatedUserHand,
  usersGraveyard,
  updatedUserDeckCount
}: HandAndDeckProps) => {
  const checkIfCardsAreTheSameFactionAndReturnMargin = (
    cardData: WarriorCardDataType | TokenCardDataType
  ) => {
    if (
      (selectedCard === null || selectedCard === 'noCard') &&
      (selectedTokenCard === null || selectedTokenCard === 'noCard')
    ) {
      return '0';
    }

    if (
      (selectedCard === null || selectedCard === 'noCard') &&
      selectedTokenCard !== null &&
      selectedTokenCard !== 'noCard'
    ) {
      if (
        cardData.type === 'Warrior' &&
        selectedTokenCard.faction === cardData.faction
      ) {
        return '-2rem';
      } else {
        return '0';
      }
    }

    if (
      (selectedTokenCard === null || selectedTokenCard === 'noCard') &&
      selectedCard !== null &&
      selectedCard !== 'noCard'
    ) {
      if (
        cardData.type === 'Token' &&
        selectedCard.faction === cardData.faction
      ) {
        return '-2rem';
      } else {
        return '0';
      }
    }
  };

  // const checkIfCardsAreTheSameFactionAndReturnBorder = (
  //   cardData: WarriorCardDataType | TokenCardDataType
  // ) => {
  //   if (
  //     (selectedCard === null || selectedCard === 'noCard') &&
  //     (selectedTokenCard === null || selectedTokenCard === 'noCard')
  //   ) {
  //     return '';
  //   }

  //   if (
  //     (selectedCard === null || selectedCard === 'noCard') &&
  //     selectedTokenCard !== null &&
  //     selectedTokenCard !== 'noCard'
  //   ) {
  //     if (
  //       cardData.type === 'Warrior' &&
  //       selectedTokenCard.faction === cardData.faction
  //     ) {
  //       return '2px solid #13f76e';
  //     } else {
  //       return '';
  //     }
  //   }

  //   if (
  //     (selectedTokenCard === null || selectedTokenCard === 'noCard') &&
  //     selectedCard !== null &&
  //     selectedCard !== 'noCard'
  //   ) {
  //     if (
  //       cardData.type === 'Token' &&
  //       selectedCard.faction === cardData.faction
  //     ) {
  //       return '2px solid #13f76e';
  //     } else {
  //       return '';
  //     }
  //   }
  // };

  return (
    <Box>
      <Grid container>
        <Grid xs={0} md={usersHand.length > 7 ? 0 : 1} xl={1} />

        <Grid xs={12} md={usersHand.length > 7 ? 10 : 9} xl={8}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              mt: '2rem'
            }}
          >
            {updatedUserHand !== null
              ? updatedUserHand.map((cardData, index) => {
                  return (
                    <>
                      {cardData.type === 'Warrior' && (
                        <Tooltip
                          placement="top"
                          title={
                            <>
                              <Typography
                                textAlign="center"
                                fontWeight="bold"
                                variant="body1"
                              >
                                {cardData.abilityName}
                              </Typography>

                              <Typography variant="subtitle1">
                                {cardData.abilityDescription}
                              </Typography>
                            </>
                          }
                        >
                          <Box
                            sx={{
                              position: 'relative',
                              width: 'calc(8rem + 6px)',
                              height: 'calc(12rem + 6px)',
                              borderRadius: '4px',
                              ':hover': {
                                '.showCardInfo': {
                                  visibility: disableCardHoverInfo
                                    ? ''
                                    : 'visible'
                                },
                                '.cardImg': {
                                  opacity: disableCardHoverInfo ? '' : 0.5
                                },
                                transform: 'scale(1.2)',
                                transformOrigin: '50% 100%',
                                zIndex: 1000
                              },
                              display: selectedCard === cardData ? 'none' : '',
                              zIndex: 0,
                              mt:
                                !disableCardSelection &&
                                checkIfCardsAreTheSameFactionAndReturnMargin(
                                  cardData
                                ) === '-2rem'
                                  ? '-2rem'
                                  : ''
                            }}
                            onClick={() => {
                              if (!disableCardSelection) {
                                setSelectedCard(cardData);
                              }
                            }}
                          >
                            {checkIfCardsAreTheSameFactionAndReturnMargin(
                              cardData
                            ) === '-2rem' &&
                              !disableCardSelection && (
                                <Box className="cardGlow" />
                              )}
                            <Box
                              sx={{
                                position: 'absolute',
                                top: 5,
                                left: 5,
                                width: '8rem',
                                height: '12rem',
                                borderRadius: '5px',
                                bgcolor: 'black',
                                zIndex: index + 1
                              }}
                            >
                              <Box
                                className="cardImg"
                                key={index}
                                component="img"
                                src={require(
                                  `../../../../assets/Card/Warrior/${cardData.rarity}/${cardData.imgUrl}`
                                )}
                                sx={{
                                  position: 'absolute',
                                  width: '8rem',
                                  height: '12rem',
                                  borderRadius: '4px',
                                  zIndex: index + 2
                                }}
                              />
                              <Box
                                className="showCardInfo"
                                sx={{
                                  position: 'absolute',
                                  visibility: 'hidden',
                                  width: '8rem',
                                  height: '12rem',
                                  borderRadius: '4px',
                                  zIndex: index + 3
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      textShadow:
                                        '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                                    }}
                                  >
                                    {cardData.name}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      textShadow:
                                        '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                                    }}
                                  >
                                    {cardData.faction}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      textShadow:
                                        '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                                    }}
                                  >
                                    ATK: {cardData.attack}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      textShadow:
                                        '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                                    }}
                                  >
                                    DEF: {cardData.defence}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Tooltip>
                      )}
                      {cardData.type === 'Token' && (
                        <Box
                          sx={{
                            position: 'relative',
                            width: 'calc(8rem + 6px)',
                            height: 'calc(12rem + 6px)',
                            borderRadius: '4px',
                            ':hover': {
                              '.showCardInfo': {
                                visibility: disableCardHoverInfo
                                  ? ''
                                  : 'visible'
                              },
                              '.cardImg': {
                                opacity: disableCardHoverInfo ? '' : 0.5
                              },
                              transform: 'scale(1.2)',
                              transformOrigin: '50% 100%',
                              zIndex: 1000
                            },
                            display:
                              selectedTokenCard === cardData ? 'none' : '',
                            zIndex: 0,
                            mt:
                              !disableCardSelection &&
                              checkIfCardsAreTheSameFactionAndReturnMargin(
                                cardData
                              ) === '-2rem'
                                ? '-2rem'
                                : ''
                          }}
                          onClick={() => {
                            if (!disableCardSelection) {
                              setSelectedTokenCard(cardData);
                            }
                          }}
                        >
                          {checkIfCardsAreTheSameFactionAndReturnMargin(
                            cardData
                          ) === '-2rem' &&
                            !disableCardSelection && (
                              <Box className="cardGlow" />
                            )}
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 5,
                              left: 5,
                              width: '8rem',
                              height: '12rem',
                              borderRadius: '5px',
                              bgcolor: 'black',
                              zIndex: index + 1
                            }}
                          >
                            <Box
                              className="cardImg"
                              key={index}
                              component="img"
                              src={require(
                                `../../../../assets/Card/Token/${cardData.rarity}/${cardData.imgUrl}`
                              )}
                              sx={{
                                position: 'absolute',
                                width: '8rem',
                                height: '12rem',
                                borderRadius: '4px',
                                zIndex: index + 2
                              }}
                            />
                            <Box
                              className="showCardInfo"
                              sx={{
                                position: 'absolute',
                                visibility: 'hidden',
                                width: '8rem',
                                height: '12rem',
                                borderRadius: '4px',
                                zIndex: index + 3
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography
                                  sx={{
                                    textShadow:
                                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                                  }}
                                >
                                  {cardData.name}
                                </Typography>
                                <Typography
                                  sx={{
                                    textShadow:
                                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                                  }}
                                >
                                  {cardData.faction}
                                </Typography>

                                <Typography
                                  sx={{
                                    textShadow:
                                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                                  }}
                                >
                                  +{cardData.factionBuff}%
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </>
                  );
                })
              : usersHand.map((cardData, index) => {
                  return (
                    <>
                      {cardData.type === 'Warrior' && (
                        <Tooltip
                          placement="top"
                          title={
                            <>
                              <Typography
                                textAlign="center"
                                fontWeight="bold"
                                variant="body1"
                              >
                                {cardData.abilityName}
                              </Typography>

                              <Typography variant="subtitle1">
                                {cardData.abilityDescription}
                              </Typography>
                            </>
                          }
                        >
                          <Box
                            sx={{
                              position: 'relative',
                              width: 'calc(8rem + 6px)',
                              height: 'calc(12rem + 6px)',
                              borderRadius: '4px',
                              ':hover': {
                                '.showCardInfo': {
                                  visibility: disableCardHoverInfo
                                    ? ''
                                    : 'visible'
                                },
                                '.cardImg': {
                                  opacity: disableCardHoverInfo ? '' : 0.5
                                },
                                transform: 'scale(1.2)',
                                transformOrigin: '50% 100%',
                                zIndex: 1000
                              },
                              display: selectedCard === cardData ? 'none' : '',
                              zIndex: 0,
                              mt:
                                !disableCardSelection &&
                                checkIfCardsAreTheSameFactionAndReturnMargin(
                                  cardData
                                ) === '-2rem'
                                  ? '-2rem'
                                  : ''
                            }}
                            onClick={() => {
                              if (!disableCardSelection) {
                                setSelectedCard(cardData);
                              }
                            }}
                          >
                            {checkIfCardsAreTheSameFactionAndReturnMargin(
                              cardData
                            ) === '-2rem' &&
                              !disableCardSelection && (
                                <Box className="cardGlow" />
                              )}
                            <Box
                              sx={{
                                position: 'absolute',
                                top: 5,
                                left: 5,
                                width: '8rem',
                                height: '12rem',
                                borderRadius: '5px',
                                bgcolor: 'black',
                                zIndex: index + 1
                              }}
                            >
                              <Box
                                className="cardImg"
                                key={index}
                                component="img"
                                src={require(
                                  `../../../../assets/Card/Warrior/${cardData.rarity}/${cardData.imgUrl}`
                                )}
                                sx={{
                                  position: 'absolute',
                                  width: '8rem',
                                  height: '12rem',
                                  borderRadius: '4px',
                                  zIndex: index + 2
                                }}
                              />
                              <Box
                                className="showCardInfo"
                                sx={{
                                  position: 'absolute',
                                  visibility: 'hidden',
                                  width: '8rem',
                                  height: '12rem',
                                  borderRadius: '4px',
                                  zIndex: index + 3
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      textShadow:
                                        '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                                    }}
                                  >
                                    {cardData.name}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      textShadow:
                                        '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                                    }}
                                  >
                                    {cardData.faction}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      textShadow:
                                        '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                                    }}
                                  >
                                    ATK: {cardData.attack}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      textShadow:
                                        '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                                    }}
                                  >
                                    DEF: {cardData.defence}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Tooltip>
                      )}
                      {cardData.type === 'Token' && (
                        <Box
                          sx={{
                            position: 'relative',
                            width: 'calc(8rem + 6px)',
                            height: 'calc(12rem + 6px)',
                            borderRadius: '4px',
                            ':hover': {
                              '.showCardInfo': {
                                visibility: disableCardHoverInfo
                                  ? ''
                                  : 'visible'
                              },
                              '.cardImg': {
                                opacity: disableCardHoverInfo ? '' : 0.5
                              },
                              transform: 'scale(1.2)',
                              transformOrigin: '50% 100%',
                              zIndex: 1000
                            },
                            display:
                              selectedTokenCard === cardData ? 'none' : '',
                            zIndex: 0,
                            mt:
                              !disableCardSelection &&
                              checkIfCardsAreTheSameFactionAndReturnMargin(
                                cardData
                              ) === '-2rem'
                                ? '-2rem'
                                : ''
                          }}
                          onClick={() => {
                            if (!disableCardSelection) {
                              setSelectedTokenCard(cardData);
                            }
                          }}
                        >
                          {checkIfCardsAreTheSameFactionAndReturnMargin(
                            cardData
                          ) === '-2rem' &&
                            !disableCardSelection && (
                              <Box className="cardGlow" />
                            )}
                          <Box
                            sx={{
                              position: 'absolute',
                              top: 5,
                              left: 5,
                              width: '8rem',
                              height: '12rem',
                              borderRadius: '5px',
                              bgcolor: 'black',
                              zIndex: index + 1
                            }}
                          >
                            <Box
                              className="cardImg"
                              key={index}
                              component="img"
                              src={require(
                                `../../../../assets/Card/Token/${cardData.rarity}/${cardData.imgUrl}`
                              )}
                              sx={{
                                position: 'absolute',
                                width: '8rem',
                                height: '12rem',
                                borderRadius: '4px',
                                zIndex: index + 2
                              }}
                            />
                            <Box
                              className="showCardInfo"
                              sx={{
                                position: 'absolute',
                                visibility: 'hidden',
                                width: '8rem',
                                height: '12rem',
                                borderRadius: '4px',
                                zIndex: index + 3
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                <Typography
                                  sx={{
                                    textShadow:
                                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                                  }}
                                >
                                  {cardData.name}
                                </Typography>
                                <Typography
                                  sx={{
                                    textShadow:
                                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                                  }}
                                >
                                  {cardData.faction}
                                </Typography>

                                <Typography
                                  sx={{
                                    textShadow:
                                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                                  }}
                                >
                                  +{cardData.factionBuff}%
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </>
                  );
                })}
          </Box>
        </Grid>
        <Grid xs={0} md={2} xl={3} sx={{ display: 'flex', width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              width: '100%'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'end', height: '100%' }}>
              <Box
                sx={{
                  bgcolor: 'black',
                  width: '7rem',
                  height: '10.5rem',
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'end',
                  backgroundImage: `url(${deckBackgroundImage})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  borderRadius: '4px'
                }}
              >
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  sx={{
                    pr: '0.5rem',
                    textShadow:
                      '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                  }}
                >
                  {updatedUserDeckCount === null
                    ? usersDeckCount
                    : updatedUserDeckCount}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                bgcolor: 'black',
                width: '5rem',
                height: '7.5rem',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'end',
                backgroundImage: `url(${deckBackgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '4px'
              }}
            >
              <Typography
                variant="h4"
                fontWeight="bold"
                sx={{
                  pr: '0.5rem',
                  textShadow:
                    '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black'
                }}
              >
                {usersGraveyard.length}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
