import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography
} from '@mui/material';
import {
  ConnectSocketAdditionalParams,
  DeckType
} from '../../../../types/types';

type PlayButtonScreenProps = {
  connectSocket: (additionalParams: ConnectSocketAdditionalParams) => void;
  userDecks: DeckType[];
  selectedDeckName: string;
  setSelectedDeckName: (value: string) => void;
};

export const PlayButtonScreen = ({
  connectSocket,
  userDecks,
  selectedDeckName,
  setSelectedDeckName
}: PlayButtonScreenProps) => {
  const [lobbyUsers, setLobbyUsers] = useState<number | 'error' | null>(null);
  const [usersInGame, setUsersInGame] = useState<number | 'error' | null>(null);

  useEffect(() => {
    fetchLobbyUsers();
    fetchUsersInGame();
  }, []);

  const fetchLobbyUsers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TCG_URL}/globalLobby`
      );
      const data = await response.json();
      console.log('fetchLobbyUsers data', data);
      setLobbyUsers(data.globalLobbyCount);
    } catch (error) {
      setLobbyUsers('error');
    }
  };

  const fetchUsersInGame = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TCG_URL}/gameRooms`
      );
      const data = await response.json();
      setUsersInGame(data.totalUsersPlaying);
    } catch (error) {
      setUsersInGame('error');
    }
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          height: `calc(100vh - 4rem)`
        }}
      >
        <Box
          sx={{ display: 'flex', mt: '-6rem', justifyContent: 'space-between' }}
        >
          {lobbyUsers !== null && lobbyUsers !== 'error' && (
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ pr: '2rem', color: lobbyUsers > 0 ? 'green' : 'red' }}
            >
              {lobbyUsers} waiting to matchup
            </Typography>
          )}
          {usersInGame !== null && usersInGame !== 'error' && (
            <Typography
              variant="h6"
              fontWeight="bold"
              sx={{ color: usersInGame > 0 ? 'green' : 'red' }}
            >
              {usersInGame} currently playing
            </Typography>
          )}
        </Box>
        <Box sx={{ pt: '4rem' }}>
          <Button
            size="large"
            variant="contained"
            onClick={() =>
              connectSocket({ botGame: false, deckName: selectedDeckName })
            }
            sx={{ mr: '2rem' }}
            disabled={userDecks.length <= 0}
          >
            Play VS other Users
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={() =>
              connectSocket({ botGame: true, deckName: selectedDeckName })
            }
            disabled={userDecks.length <= 0}
          >
            Play VS Bot
          </Button>
        </Box>
        <Box sx={{ pt: '2rem' }}>
          {userDecks.length > 0 ? (
            <FormControl sx={{ width: '250px' }}>
              <InputLabel id="deck-select-label">Select Deck</InputLabel>
              <Select
                labelId="deck-select-label"
                id="deck-select"
                label="Select Deck"
                onChange={(e) => {
                  setSelectedDeckName(e.target.value);
                }}
                value={selectedDeckName}
              >
                {userDecks.map((deck) => {
                  return (
                    <MenuItem value={deck.deckName}>{deck.deckName}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          ) : (
            <Button>Please create a deck</Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
