import React from 'react';
import { Button, Grid } from '@mui/material';
import whiteListTop from '../../../assets/Images/white-list-top.png';
import whiteListBottom from '../../../assets/Images/white-list-bottom.png';

export const WhitePaper = () => (
  <Grid
    item
    container
    justifyContent="center"
    sx={{
      p: '2rem',
    }}
  >
    <Grid item>
      <div style={{ textAlign: 'center' }}>
        <img
          src={whiteListTop}
          alt="First"
          style={{
            width: '90%',
            height: 'auto',
            display: 'block',
            margin: '0 auto'
          }}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '20px' }}
          onClick={() =>
            window.open('https://forms.gle/X1QmANkM7roeHiD18', '_blank')
          }
        >
          Join Now
        </Button>
        <img
          src={whiteListBottom}
          alt="Second"
          style={{
            width: '90%',
            height: 'auto',
            display: 'block',
            margin: '20px auto 0'
          }}
        />
      </div>
    </Grid>
  </Grid>
);
