import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import {
  CardGameOpponentState,
  CardGameUserState,
  CardGameRoomState,
  WarriorCardDataType,
  TokenCardDataType,
  SelectedCardsFromGameServerType,
  DeckType
} from '../../../types/types';
import { useSocket } from '../../../context/SocketContext';
import {
  GameMessageScreen,
  MatchBattleScreen,
  MatchEndScreen,
  PlayButtonScreen
} from './components';
import { useUser } from '../../../contexts/UserContext';
import { betaStarterDecks } from '../../../constants/betaStarterDecks';

// type PlayProps = {};

export const Play = () => {
  const { socket, connectSocket, disconnectSocket, connectionError } =
    useSocket();

  const { user } = useUser();

  const userDecks = betaStarterDecks;

  const [gameMessage, setGameMessage] = useState<string>('');
  const [userState, setUserState] = useState<CardGameUserState | null>(null);
  const [opponentState, setOpponentState] =
    useState<CardGameOpponentState | null>(null);
  const [opponentUsername, setOpponentUsername] = useState<string>('');
  const [selectedCard, setSelectedCard] = useState<
    WarriorCardDataType | 'noCard' | null
  >(null);
  const [selectedTokenCard, setSelectedTokenCard] = useState<
    TokenCardDataType | 'noCard' | null
  >(null);
  const [opponentSelectedCard, setOpponentSelectedCard] = useState<
    WarriorCardDataType | 'noCard' | null
  >(null);
  const [opponentSelectedTokenCard, setOpponentSelectedTokenCard] = useState<
    TokenCardDataType | 'noCard' | null
  >(null);
  const [gameWinner, setGameWinner] = useState<string>('');
  const [roundStartTimer, setRoundStartTimer] = useState<Date | null>(null);
  const [countdownTimer, setCountdownTimer] = useState<number>(0);
  const [hasOpponentDisconnected, setHasOpponentDisconnected] =
    useState<boolean>(false);

  const [disableCardSelection, setDisableCardSelection] =
    useState<boolean>(false);

  const [disableCardHoverInfo, setDisbaleCardHoverInfo] =
    useState<boolean>(false);

  const [playerCardFinalAttack, setPlayerCardFinalAttack] = useState<
    number | null
  >(null);
  const [playerCardFinalDefence, setPlayerCardFinalDefence] = useState<
    number | null
  >(null);
  const [playerBattleDamageTaken, setPlayerBattleDamageTaken] = useState<
    number | null
  >(null);
  const [playerEffectDamageTaken, setPlayerEffectDamageTaken] = useState<
    number | null
  >(null);
  const [playerHealAmount, setPlayerHealAmount] = useState<number | null>(null);

  const [opponentCardFinalAttack, setOpponentCardFinalAttack] = useState<
    number | null
  >(null);
  const [opponentCardFinalDefence, setOpponentCardFinalDefence] = useState<
    number | null
  >(null);
  const [opponentBattleDamageTaken, setOpponentBattleDamageTaken] = useState<
    number | null
  >(null);
  const [opponentEffectDamageTaken, setOpponentEffectDamageTaken] = useState<
    number | null
  >(null);
  const [opponentHealAmount, setOpponentHealAmount] = useState<number | null>(
    null
  );
  const [selectedDeckName, setSelectedDeckName] = useState<string>(
    userDecks.length > 0 ? userDecks[0].deckName : ''
  );

  const [updatedUserHand, setUpdatedUserHand] = useState<
    (WarriorCardDataType | TokenCardDataType)[] | null
  >(null);

  const [updatedUserDeckCount, setUpdatedUserDeckCount] = useState<
    number | null
  >(null);

  useEffect(() => {
    if (socket) {
      socket.on('globalLobby', () => {
        setGameMessage('Finding Match');
      });
      socket.on('matchFound', () => {
        setGameMessage('Match Found');
      });
      socket.on('gameStart', (gameState: CardGameRoomState) => {
        setGameMessage('');
        setUserState(gameState.user);
        setOpponentState(gameState.opponent);
        setOpponentUsername(gameState.opponentUsername);
        setRoundStartTimer(gameState.roundStartTime);
      });

      socket.on(
        'showOpponentsCard',
        (gameStateCardData: SelectedCardsFromGameServerType) => {
          setDisableCardSelection(true);

          setUpdatedUserHand(gameStateCardData.playerUpdatedHand);
          setUpdatedUserDeckCount(gameStateCardData.playerUpdatedDeckCount);

          setSelectedCard(gameStateCardData.playerSelectedCard);
          setSelectedTokenCard(gameStateCardData.playerSelectedTokenCard);
          setOpponentSelectedCard(gameStateCardData.opponentSelectedCard);
          setOpponentSelectedTokenCard(
            gameStateCardData.opponentSelectedTokenCard
          );

          setPlayerCardFinalAttack(gameStateCardData.playerCardFinalAttack);
          setPlayerCardFinalDefence(gameStateCardData.playerCardFinalDefence);
          setPlayerBattleDamageTaken(gameStateCardData.playerBattleDamageTaken);
          setPlayerEffectDamageTaken(gameStateCardData.playerEffectDamageTaken);
          setPlayerHealAmount(gameStateCardData.playerHealAmount);
          setOpponentCardFinalAttack(gameStateCardData.opponentCardFinalAttack);
          setOpponentCardFinalDefence(
            gameStateCardData.opponentCardFinalDefence
          );
          setOpponentBattleDamageTaken(
            gameStateCardData.opponentBattleDamageTaken
          );
          setOpponentEffectDamageTaken(
            gameStateCardData.opponentEffectDamageTaken
          );
          setOpponentHealAmount(gameStateCardData.opponentHealAmount);
          setCountdownTimer(9);
        }
      );

      socket.on(
        'opponentNoCard',
        (gameStateCardData: SelectedCardsFromGameServerType) => {
          setDisableCardSelection(true);

          setUpdatedUserHand(gameStateCardData.playerUpdatedHand);
          setUpdatedUserDeckCount(gameStateCardData.playerUpdatedDeckCount);

          setSelectedCard(gameStateCardData.playerSelectedCard);
          setSelectedTokenCard(gameStateCardData.playerSelectedTokenCard);
          setOpponentSelectedCard(gameStateCardData.opponentSelectedCard);
          setOpponentSelectedTokenCard(
            gameStateCardData.opponentSelectedTokenCard
          );

          setPlayerCardFinalAttack(gameStateCardData.playerCardFinalAttack);
          setPlayerCardFinalDefence(gameStateCardData.playerCardFinalDefence);
          setPlayerBattleDamageTaken(gameStateCardData.playerBattleDamageTaken);
          setPlayerEffectDamageTaken(gameStateCardData.playerEffectDamageTaken);
          setPlayerHealAmount(gameStateCardData.playerHealAmount);
          setOpponentCardFinalAttack(gameStateCardData.opponentCardFinalAttack);
          setOpponentCardFinalDefence(
            gameStateCardData.opponentCardFinalDefence
          );
          setOpponentBattleDamageTaken(
            gameStateCardData.opponentBattleDamageTaken
          );
          setOpponentEffectDamageTaken(
            gameStateCardData.opponentEffectDamageTaken
          );
          setOpponentHealAmount(gameStateCardData.opponentHealAmount);

          setCountdownTimer(9);
        }
      );

      socket.on('roundResolved', (gameState: CardGameRoomState) => {
        setUpdatedUserDeckCount(null);
        setUpdatedUserHand(null);
        setSelectedCard(null);
        setSelectedTokenCard(null);
        setOpponentSelectedCard(null);
        setOpponentSelectedTokenCard(null);
        setDisableCardSelection(false);
        setUserState(gameState.user);
        setOpponentState(gameState.opponent);
        setRoundStartTimer(gameState.roundStartTime);
        setPlayerCardFinalAttack(null);
        setPlayerCardFinalDefence(null);
        setPlayerBattleDamageTaken(null);
        setPlayerEffectDamageTaken(null);
        setPlayerHealAmount(null);
        setOpponentCardFinalAttack(null);
        setOpponentCardFinalDefence(null);
        setOpponentBattleDamageTaken(null);
        setOpponentEffectDamageTaken(null);
        setOpponentHealAmount(null);
      });
      socket.on('gameOver', (winner) => {
        setGameWinner(winner.winner);
        disconnectSocket();
      });

      socket.on('reconnected', (gameState: CardGameRoomState) => {
        setUserState(gameState.user);
        setOpponentState(gameState.opponent);
        setOpponentUsername(gameState.opponentUsername);
        setRoundStartTimer(gameState.roundStartTime);

        if (!!gameState.winner && gameState.winner !== null) {
          setGameWinner(gameState.winner);
          disconnectSocket();
        }
      });

      socket.on('opponentDisconnected', () => {
        setHasOpponentDisconnected(true);
      });
      socket.on('opponentReconnected', () => {
        setHasOpponentDisconnected(false);
      });

      // Cleanup event listeners on component unmount
      return () => {
        socket.off('globalLobby');
        socket.off('matchFound');
        socket.off('gameStart');
        socket.off('showOpponentsCard');
        socket.off('roundResolved');
        socket.off('gameOver');
        socket.off('reconnected');
      };
    }
  }, [socket]);

  const onCardSubmit = () => {
    if (socket) {
      setDisableCardSelection(true);

      socket.emit('submitCardSelection', {
        playerUsername: user.username,
        warriorCard: selectedCard,
        tokenCard: selectedTokenCard
      });

      if (userState !== null) {
        const updatedUserState = { ...userState };
        if (selectedCard !== null) {
          const cardIndex = userState.hand.findIndex(
            (card) => selectedCard === card
          );
          updatedUserState.hand.splice(cardIndex, 1);
        }
        if (selectedTokenCard !== null) {
          const tokenCardIndex = userState.hand.findIndex(
            (card) => selectedTokenCard === card
          );
          updatedUserState.hand.splice(tokenCardIndex, 1);
        }
        setUserState({ ...userState, hand: updatedUserState.hand });
      }
    }
  };

  const resetGame = () => {
    setUpdatedUserDeckCount(null);
    setUpdatedUserHand(null);
    setSelectedCard(null);
    setSelectedTokenCard(null);
    setOpponentSelectedCard(null);
    setOpponentSelectedTokenCard(null);
    setDisableCardSelection(false);
    setUserState(null);
    setOpponentState(null);
    setOpponentUsername('');
    setRoundStartTimer(null);
    setGameWinner('');
    setPlayerCardFinalAttack(null);
    setPlayerCardFinalDefence(null);
    setPlayerBattleDamageTaken(null);
    setPlayerEffectDamageTaken(null);
    setPlayerHealAmount(null);
    setOpponentCardFinalAttack(null);
    setOpponentCardFinalDefence(null);
    setOpponentBattleDamageTaken(null);
    setOpponentEffectDamageTaken(null);
    setOpponentHealAmount(null);
  };

  return (
    <Box sx={{ width: '100%' }}>
      {!connectionError && !socket && !gameWinner && (
        <PlayButtonScreen
          connectSocket={connectSocket}
          userDecks={userDecks}
          selectedDeckName={selectedDeckName}
          setSelectedDeckName={setSelectedDeckName}
        />
      )}

      {!connectionError && socket && gameMessage && (
        <GameMessageScreen message={gameMessage} />
      )}

      {!connectionError &&
        socket &&
        !gameMessage &&
        userState &&
        opponentState && (
          <MatchBattleScreen
            username={user.username}
            userState={userState}
            opponentUsername={opponentUsername}
            opponentState={opponentState}
            onCardSubmit={onCardSubmit}
            roundStartTimer={roundStartTimer}
            countdownTimer={countdownTimer}
            setCountdownTimer={setCountdownTimer}
            hasOpponentDisconnected={hasOpponentDisconnected}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            selectedTokenCard={selectedTokenCard}
            setSelectedTokenCard={setSelectedTokenCard}
            opponentSelectedCard={opponentSelectedCard}
            opponentSelectedTokenCard={opponentSelectedTokenCard}
            disableCardSelection={disableCardSelection}
            disableCardHoverInfo={disableCardHoverInfo}
            setDisbaleCardHoverInfo={setDisbaleCardHoverInfo}
            playerCardFinalAttack={playerCardFinalAttack}
            playerCardFinalDefence={playerCardFinalDefence}
            playerBattleDamageTaken={playerBattleDamageTaken}
            playerEffectDamageTaken={playerEffectDamageTaken}
            playerHealAmount={playerHealAmount}
            opponentCardFinalAttack={opponentCardFinalAttack}
            opponentCardFinalDefence={opponentCardFinalDefence}
            opponentBattleDamageTaken={opponentBattleDamageTaken}
            opponentEffectDamageTaken={opponentEffectDamageTaken}
            opponentHealAmount={opponentHealAmount}
            updatedUserHand={updatedUserHand}
            updatedUserDeckCount={updatedUserDeckCount}
          />
        )}

      {!connectionError && !socket && gameWinner && (
        <MatchEndScreen
          winner={gameWinner}
          username={user.username}
          resetGame={resetGame}
          connectSocket={connectSocket}
          userDecks={userDecks}
          selectedDeckName={selectedDeckName}
          setSelectedDeckName={setSelectedDeckName}
        />
      )}

      {connectionError && <GameMessageScreen message={connectionError} />}
    </Box>
  );
};
