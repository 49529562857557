import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton
} from '@mui/material';
import { useNetwork } from '../../../utils/network';
import { useShowSnackbar } from '../../../utils/snackbar';
import WarningIcon from '@mui/icons-material/Warning';
import { CountdownTimer } from '../../../components/CountdownTimer';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// Define types for presale details and transactions
interface Transaction {
  signature: string;
  amount: number;
  datetime: string;
}

interface PresaleItem {
  quantity: number;
  package: {
    id: number;
    name: string;
    description: string;
    price: number;
  };
}

interface PresaleDetails {
  email: string;
  walletAddress: string;
  totalPledged: number;
  items: PresaleItem[];
  totalTransferredSOL: number;
  remainingBalance: number;
  transactions: Transaction[];
}

export const PresalePage: React.FC = () => {
  const { get } = useNetwork();
  const { showSnackbar } = useShowSnackbar();
  const SOL_TO_USD_RATE = 200;
  const PRESALE_WALLET_ADDRESS = '9XePQCjvSPFypN3J7cJTfd7hjcARfbFSGTZdR9RQzavp';
  const [loading, setLoading] = useState<boolean>(true);
  const [presaleDetails, setPresaleDetails] = useState<PresaleDetails | null>(
    null
  );
  const [loadingRefresh, setLoadingRefresh] = useState<boolean>(false);
  let remainingBalance = presaleDetails
    ? presaleDetails.totalTransferredSOL * SOL_TO_USD_RATE
    : 0;
  const allPaid = presaleDetails &&
    presaleDetails.totalTransferredSOL * SOL_TO_USD_RATE >=
    presaleDetails.items.reduce((sum, item) => sum + item.package.price * item.quantity, 0);
  // Fetch the initial presale details
  const getDetails = async () => {
    setLoading(true);
    try {
      const response = await get('/presale', true);
      setPresaleDetails(response);
    } catch (error: any) {
      showSnackbar(error.response.data.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  // Refresh payment status
  const refreshPaymentStatus = async () => {
    setLoadingRefresh(true);
    try {
      const response = await get('/presale/status', true);
      setPresaleDetails((prevDetails) => ({
        ...prevDetails!,
        totalTransferredSOL: response.totalTransferredSOL,
        transactions: response.transactions
      }));
    } catch (error: any) {
      showSnackbar(error.response.data.message, 'error');
    } finally {
      setLoadingRefresh(false);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  if (loading) return <CircularProgress />;
  console.log(remainingBalance)
  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Whitelist Presale Ends
      </Typography>
      <CountdownTimer
        nextGiveaway={new Date(Date.UTC(2024, 10, 16, 23, 0, 0))}
        fieldsToShow={['hours', 'minutes', 'seconds']}
      />
      {presaleDetails ? (
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
            padding: 2,
            borderRadius: 4,
            marginTop: 2,
          }}
        >
          {allPaid ?
            <>
              <Typography>Your transaction has been verified, thank you for your support.

                Next steps will be announced closer to listing date.

                If you would like to add more packs, please notify us via discord.
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Package Name</TableCell>
                      <TableCell>Price (USD)</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Total (USD)</TableCell>
                      <TableCell>Status</TableCell> {/* Add Status column */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {presaleDetails.items.map((item, index) => {
                      const totalPriceUSD = item.package.price * item.quantity;
                      let status = 'Unpaid';
                      if (remainingBalance >= totalPriceUSD) {
                        status = 'Paid';
                        remainingBalance -= totalPriceUSD; // Deduct the amount from remaining balance
                      }
                      return (
                        <TableRow key={index}>
                          <TableCell>{item.package.name}</TableCell>
                          <TableCell>{item.package.price}</TableCell>
                          <TableCell>{item.quantity}</TableCell>
                          <TableCell>{totalPriceUSD}</TableCell>
                          <TableCell>{status}</TableCell> {/* Display status */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </> :
            <>
              <Typography
                variant="h6"
                style={{
                  color: 'red',
                  fontWeight: 'bold',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <WarningIcon style={{ marginRight: '8px' }} />
                Double-check the address you’re sending Solana to, as crypto
                transactions are irreversible!
              </Typography>
              <Typography
                variant="body1"
                style={{
                  color: 'red',
                  fontWeight: 'bold',
                  marginTop: '8px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                Please avoid using shared wallets or exchanges to send funds, as this may result in unsuccessful transfers. Always use your personal wallet to ensure a successful transaction.
              </Typography>
              <TextField
                label="Your Wallet Address"
                value={presaleDetails.walletAddress}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => navigator.clipboard.writeText(presaleDetails.walletAddress)}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ marginTop: 2, marginBottom: 2 }}
              />
              <TextField
                label="Presale Wallet Address"
                value={PRESALE_WALLET_ADDRESS}
                variant="outlined"
                fullWidth
                InputProps={{
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => navigator.clipboard.writeText(PRESALE_WALLET_ADDRESS)}>
                        <ContentCopyIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ marginTop: 2, marginBottom: 2 }}
              />


              {/* Package Details Table */}
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Package Name</TableCell>
                      <TableCell>Price (USD)</TableCell>
                      <TableCell>Quantity</TableCell>
                      <TableCell>Total (USD)</TableCell>
                      <TableCell>Status</TableCell> {/* Add Status column */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {presaleDetails.items.map((item, index) => {
                      const totalPriceUSD = item.package.price * item.quantity;
                      let status = 'Unpaid';
                      if (remainingBalance >= totalPriceUSD) {
                        status = 'Paid';
                        remainingBalance -= totalPriceUSD; // Deduct the amount from remaining balance
                      }
                      return (
                        <TableRow key={index}>
                          <TableCell>{item.package.name}</TableCell>
                          <TableCell>{item.package.price}</TableCell>
                          <TableCell>{item.quantity}</TableCell>
                          <TableCell>{totalPriceUSD}</TableCell>
                          <TableCell>{status}</TableCell> {/* Display status */}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ marginBottom: 2 }}>
                <Typography variant="h6">
                  If you pledged for the $333 pack please send 1.665 SOLANA
                </Typography>

                <Typography variant="h6" sx={{ marginTop: 1 }}>
                  If you pledged for the $699 pack please send 3.495 SOLANA
                </Typography>

                <Typography variant="h6" sx={{ marginTop: 1 }}>
                  If you pledged for the $999 pack please send 4.995 SOLANA PER PACK
                </Typography>

                <Typography variant="h6" sx={{ marginTop: 1 }}>
                  Eg. if you pledged for 3 packs send 3x the amount. So for 3x $999
                  packs it'll be 4.995 SOLANA * 3 = 14.985 SOLANA
                </Typography>

                <Typography variant="h6" sx={{ marginTop: 1 }}>
                  SOLANA PRICE IS LOCKED AT $200USD
                </Typography>
              </Box>
              {/* Total USD Calculation */}
              <Box>
                <Typography variant="h6">
                  Total Pledged (USD):{' '}
                  {presaleDetails.items
                    .reduce(
                      (sum, item) => sum + item.package.price * item.quantity,
                      0
                    )
                    .toFixed(2)}
                </Typography>

                <Typography variant="body1" sx={{ marginTop: 1 }}>
                  Locked Rate: 1 SOL = {SOL_TO_USD_RATE} USD
                </Typography>

                <Typography variant="h6" sx={{ marginTop: 2 }}>
                  Total Received (SOL):{' '}
                  {(presaleDetails.totalTransferredSOL ?? 0).toFixed(3)} SOL
                </Typography>

                {/* <Typography variant="h6" gutterBottom>
              Total Received (USD):{' '}
              {(
                (presaleDetails.totalTransferredSOL ?? 0) * SOL_TO_USD_RATE
              ).toFixed(2)}{' '}
              USD
            </Typography> */}

                <Typography
                  variant="h6"
                  sx={{ marginTop: 2, fontWeight: 'bold', color: 'primary.main' }}
                >
                  Amount Paid Compared to Pledge:
                </Typography>
                <Typography variant="body1">
                  {`We have received ${((presaleDetails.totalTransferredSOL ?? 0) * SOL_TO_USD_RATE).toFixed(2)} USD out of your pledged amount of ${presaleDetails.items
                    .reduce(
                      (sum, item) => sum + item.package.price * item.quantity,
                      0
                    )
                    .toFixed(2)} USD`}
                </Typography>
              </Box>

              {/* Refresh Button */}
              <Button
                variant="contained"
                color="primary"
                onClick={refreshPaymentStatus}
                sx={{ marginTop: 3, display: 'flex', alignItems: 'center' }}
                disabled={loadingRefresh}
              >
                {loadingRefresh ? (
                  <>
                    <CircularProgress size={20} sx={{ color: 'white', marginRight: 1 }} />
                    Updating Payment Status...
                  </>
                ) : (
                  'Update Payment Status'
                )}
              </Button>

              {/* Transaction Table */}
              {presaleDetails.transactions?.length > 0 ? <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Transaction Signature</TableCell>
                      <TableCell>Amount (SOL)</TableCell>
                      <TableCell>Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {presaleDetails.transactions.map((transaction, index) => (
                      <TableRow key={index}>
                        <TableCell>{transaction.signature}</TableCell>
                        <TableCell>{transaction.amount.toFixed(3)}</TableCell>
                        <TableCell>
                          {new Date(transaction.datetime).toLocaleString()}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

                :
                <Typography sx={{ mt: 2 }}>No transactions found</Typography>
              }
            </>
          }
        </Box>
      ) : (
        <Typography>No presale details found.</Typography>
      )}
    </Box>
  );
};
