import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

export interface User {
  uuid: string;
  firstName: string;
  lastName: string;
  username: string;
  walletAddress: string;
  referralCode: string;
  email: string;
  tokenAmount: number;
  entries: number;
  nextFaucetClaim: string;
  activeSubscriptionId: number | null;
  hasTwoFactorAuthEnabled: boolean;
  showTwoFactorAuth: boolean;
  showReferral: boolean;
  refreshImage: string;
}

interface UserContextType {
  user: User;
  setUser: (user: User) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User>(() => {
    const storedUser = localStorage.getItem('incrypt_app_userData');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    if (user) {
      localStorage.setItem('incrypt_app_userData', JSON.stringify(user));
    } else {
      localStorage.removeItem('incrypt_app_userData');
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
