import React from 'react';
import { Button, Grid } from '@mui/material';
import whiteListTop from '../../assets/Images/white-list-top.png';
import whiteListBottom from '../../assets/Images/white-list-bottom.png';

export const JoinNow = () => (
  <Grid
    item
    xs={false} // This controls the column size, not visibility
    sm={4}
    md={7}
    container
    justifyContent="center"
    alignItems="center"
    sx={{
      height: '100vh',
      display: { xs: 'none', sm: 'flex' }, // Hide on xs, show on sm and above
    }}
  >
    <Grid item>
      <div style={{ textAlign: 'center' }}>
        <img
          src={whiteListTop}
          alt="First"
          style={{
            width: '80%',
            maxWidth: '800px',
            height: 'auto',
            display: 'block',
            margin: '0 auto',
          }}
        />
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '20px' }}
          onClick={() => window.open('https://forms.gle/X1QmANkM7roeHiD18', '_blank')}
        >
          Join Now
        </Button>
        <img
          src={whiteListBottom}
          alt="Second"
          style={{
            width: '80%',
            maxWidth: '800px',
            height: 'auto',
            display: 'block',
            margin: '20px auto 0',
          }}
        />
      </div>
    </Grid>
  </Grid>

);
