import React, { useState } from 'react';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import CountrySelect from '../../components/CountrySelect/CountrySelect';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { SignUpFormType } from '../../types/types';
import { emailValidationRegex } from '../../constants/regex';
import {
  Grid,
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  InputAdornment
} from '@mui/material';
import { useNetwork } from '../../utils/network';
import { useShowSnackbar } from '../../utils/snackbar';
import './SignUp.css'
import { JoinNow } from '../../components/WhitePaper'

export const SignUp = () => {
  const { post } = useNetwork()
  const { showSnackbar } = useShowSnackbar()
  const { search, state } = useLocation();
  const email = state?.email;
  const params = new URLSearchParams(search);
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [selectedAreaCode, setSelectedAreaCode] = useState<string>('');

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm<SignUpFormType>({
    defaultValues: {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      phone: '',
      country: undefined,
      state: '',
      referralCode: params.get('referralCode') ?? '',
    }
  });

  const onSubmit: SubmitHandler<SignUpFormType> = async (data) => {
    try {
      await post('/auth/sign-up', {
        username: data.username,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: selectedAreaCode + data.phone,
        country: data.country.label,
        state: data.state,
        referralCode: data.referralCode
      })
      setIsEmailSent(true)
      reset()
    } catch (error: any) {
      showSnackbar(error.response.data.message, 'error');
    }
  };

  return (
    <Grid container component="main" sx={{ height: '100vh' }} className='sign-up'>
      <JoinNow />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography component="h1" variant="h4">
            Sign up
          </Typography>
          {!isEmailSent ? <>
            <Box sx={{ mt: 1 }}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      id="firstName"
                      margin="normal"
                      required
                      fullWidth
                      label="First Name"
                      error={Boolean(errors.firstName)}
                      helperText={
                        errors.firstName && 'Please enter your first name'
                      }
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="lastName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      id="lastName"
                      margin="normal"
                      required
                      fullWidth
                      label="Last Name"
                      error={Boolean(errors.lastName)}
                      helperText={
                        errors.lastName && 'Please enter your last name'
                      }
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="username"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      id="username"
                      margin="normal"
                      required
                      fullWidth
                      label="Username"
                      error={Boolean(errors.username)}
                      helperText={
                        errors.username && 'Please enter a username'
                      }
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    pattern: emailValidationRegex
                  }}
                  render={({ field }) => (
                    <TextField
                      id="email"
                      margin="normal"
                      required
                      fullWidth
                      label="Email"
                      autoComplete="email"
                      error={Boolean(errors.email)}
                      helperText={errors.email && 'Please enter a valid email'}
                      {...field}
                      value={email || field.value}
                    />
                  )}
                />

                <CountrySelect control={control} onCountryChange={(areaCode) => setSelectedAreaCode(`+${areaCode}`)} />

                <Controller
                  name="phone"
                  control={control}
                  rules={{
                    required: true,
                    minLength: {
                      value: 6, // Minimum length for the phone number
                      message: 'Phone number must be at least 6 digits long'
                    },
                    maxLength: {
                      value: 15, // Maximum length for the phone number
                      message: 'Phone number must be no more than 15 digits long'
                    },
                    pattern: {
                      value: /^[0-9]+$/, // Only allow numbers
                      message: 'Phone number must contain only digits'
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      id="phone"
                      margin="normal"
                      required
                      fullWidth
                      label="Phone"
                      error={Boolean(errors.phone)}
                      helperText={errors.phone && errors.phone.message}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{selectedAreaCode}</InputAdornment>,
                      }}
                      {...field}
                    />
                  )}
                  disabled={!selectedAreaCode}
                />

                <Controller
                  name="state"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      id="state"
                      margin="normal"
                      required
                      fullWidth
                      label="State"
                      error={Boolean(errors.state)}
                      helperText={errors.state && 'Please enter your state'}
                      {...field}
                    />
                  )}
                />

                <Controller
                  name="referralCode"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <TextField
                      margin="normal"
                      fullWidth
                      label="Referral Code"
                      id="referralCode"
                      {...field}
                    />
                  )}
                />

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Sign Up
                </Button>
                <Grid container>
                  <Grid item>
                    <Link component={ReactRouterLink} to="/login" variant="body2">
                      {'Already have an account? Login'}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </Box> </> :
            <>
              <Box sx={{ mt: '2rem' }}>
                <Typography variant="body1">
                  User created successfully. Please verify your email.
                </Typography>
              </Box>
            </>
          }
        </Box>
      </Grid>
    </Grid>
  );
};