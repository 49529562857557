import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import {
  ConnectSocketAdditionalParams,
  DeckType
} from '../../../../types/types';

type MatchEndScreenProps = {
  winner: string;
  username: string;
  resetGame: () => void;
  connectSocket: (additionalParams: ConnectSocketAdditionalParams) => void;
  userDecks: DeckType[];
  selectedDeckName: string;
  setSelectedDeckName: (value: string) => void;
};

export const MatchEndScreen = ({
  winner,
  username,
  resetGame,
  connectSocket,
  userDecks,
  selectedDeckName,
  setSelectedDeckName
}: MatchEndScreenProps) => {
  const [lobbyUsers, setLobbyUsers] = useState<number | 'error' | null>(null);
  const [usersInGame, setUsersInGame] = useState<number | 'error' | null>(null);

  useEffect(() => {
    fetchLobbyUsers();
    fetchUsersInGame();
  }, []);

  const fetchLobbyUsers = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TCG_URL}/globalLobby`
      );
      const data = await response.json();
      console.log('fetchLobbyUsers data', data);
      setLobbyUsers(data.globalLobbyCount);
    } catch (error) {
      setLobbyUsers('error');
    }
  };

  const fetchUsersInGame = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_TCG_URL}/gameRooms`
      );
      const data = await response.json();
      setUsersInGame(data.totalUsersPlaying);
    } catch (error) {
      setUsersInGame('error');
    }
  };

  const playAgainHandler = (botGame: boolean) => {
    resetGame();
    connectSocket({ botGame, deckName: selectedDeckName });
  };

  const renderPlayButtons = () => {
    return (
      <>
        <Button
          size="large"
          variant="contained"
          onClick={() => playAgainHandler(false)}
          sx={{ mr: '2rem' }}
        >
          Play Again VS other Users
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() => playAgainHandler(true)}
        >
          Play Again VS Bot
        </Button>
      </>
    );
  };

  const renderDeckSelect = () => {
    return (
      <Box sx={{ pt: '2rem', display: 'flex', justifyContent: 'center' }}>
        <FormControl sx={{ width: '250px' }}>
          <InputLabel id="deck-select-label">Select Deck</InputLabel>
          <Select
            labelId="deck-select-label"
            id="deck-select"
            label="Select Deck"
            onChange={(e) => {
              setSelectedDeckName(e.target.value);
            }}
            value={selectedDeckName}
          >
            {userDecks.map((deck) => {
              return <MenuItem value={deck.deckName}>{deck.deckName}</MenuItem>;
            })}
          </Select>
        </FormControl>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: `calc(100vh - 4rem)`
      }}
    >
      <Box
        sx={{ display: 'flex', mt: '-6rem', justifyContent: 'space-between' }}
      >
        {lobbyUsers !== null && lobbyUsers !== 'error' && (
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ pr: '2rem', color: lobbyUsers > 0 ? 'green' : 'red' }}
          >
            {lobbyUsers} waiting to matchup
          </Typography>
        )}
        {usersInGame !== null && usersInGame !== 'error' && (
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ color: usersInGame > 0 ? 'green' : 'red' }}
          >
            {usersInGame} currently playing
          </Typography>
        )}
      </Box>
      {winner !== 'draw' &&
        winner !== username &&
        winner !== 'Opponent disconnected' && (
          <Box>
            <Typography variant="h1" textAlign="center" fontWeight="bold">
              You Lose
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '2rem'
              }}
            >
              {renderPlayButtons()}
            </Box>
            {renderDeckSelect()}
          </Box>
        )}
      {winner !== 'draw' &&
        (winner === username || winner === 'Opponent disconnected') && (
          <Box>
            <Typography variant="h1" textAlign="center" fontWeight="bold">
              You Win
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '2rem'
              }}
            >
              {renderPlayButtons()}
            </Box>
            {renderDeckSelect()}
          </Box>
        )}
      {winner === 'draw' && (
        <Box>
          <Typography variant="h1" textAlign="center" fontWeight="bold">
            Draw
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '2rem'
            }}
          >
            {renderPlayButtons()}
          </Box>
          {renderDeckSelect()}
        </Box>
      )}
    </Box>
  );
};
