import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Faucet } from './components';
import { TwoFactorAuthModal } from '../TwoFactorAuth/TwoFactorAuthModal';
import { ReferralModal } from './components/ReferalModal';
import { useUser } from '../../../contexts/UserContext';
import { CountdownTimer } from '../../../components/CountdownTimer';

// type DashboardProps = {};

export const Home = () => {
  const { user, setUser } = useUser();

  const [isTwoFAModalOpen, setIsTwoFAModalOpen] = useState<boolean>(
    (!user.hasTwoFactorAuthEnabled && user.showTwoFactorAuth) ?? true
  );

  const [isReferralModalOpen, setIsReferralModalOpen] = useState<boolean>(
    (!isTwoFAModalOpen && user.showReferral) ?? true
  );

  const handleTwoFAModalClose = (): void => {
    setUser({
      ...user,
      showTwoFactorAuth: false
    });
    setIsTwoFAModalOpen(false);

    setIsReferralModalOpen(true);
  };

  const handleReferralModalClose = (): void => {
    setIsReferralModalOpen(false);
    setUser({
      ...user,
      showReferral: false
    });
  };

  useEffect(() => {
    if (!isTwoFAModalOpen && user.showReferral) {
      setIsReferralModalOpen(true);
    }
  }, [isTwoFAModalOpen, user.showReferral]);

  return (
    <Grid container spacing={2} sx={{ p: '2rem' }}>
      <Grid item xs={12} sm={6} lg={4}>
        <Faucet />
      </Grid>

      <Grid item xs={12} sm={6} lg={4}>
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.12)',
            padding: 2,
            borderRadius: 4,
            marginTop: 2,
            display: 'flex', // Set the box to use flex layout
            flexDirection: 'column', // Stack items vertically
            justifyContent: 'center', // Center items vertically
            alignItems: 'center', // Center items horizontally
          }}
        >
          <Typography variant="h4" gutterBottom textAlign="center">
            Whitelist Presale Ends
          </Typography>
          <CountdownTimer
            nextGiveaway={new Date(Date.UTC(2024, 10, 16, 23, 0, 0))}
            fieldsToShow={['hours', 'minutes', 'seconds']}
          />
        </Box>
      </Grid>

      {/* 2FA Modal */}
      {isTwoFAModalOpen && (
        <TwoFactorAuthModal
          isTwoFAModalOpen={isTwoFAModalOpen}
          handleTwoFAModalClose={handleTwoFAModalClose}
        />
      )}

      {/* Referral Modal */}
      {isReferralModalOpen && (
        <ReferralModal
          isOpen={isReferralModalOpen}
          handleClose={handleReferralModalClose}
        />
      )}
    </Grid>
  );
};
