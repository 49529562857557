import React, { useEffect, useState } from 'react';
import { differenceInSeconds } from 'date-fns';
import { Box, Button, Typography } from '@mui/material';
import { CountdownTimer } from '../../../../components/CountdownTimer';
import { useUser } from '../../../../contexts/UserContext';
import { useNetwork } from '../../../../utils/network';
import { useShowSnackbar } from '../../../../utils/snackbar';
export const Faucet = () => {
  const { user, setUser } = useUser();
  const { showSnackbar } = useShowSnackbar()
  const { post } = useNetwork();
  const nextFaucetTime = new Date(user.nextFaucetClaim)

  const [faucetClaimed, setFaucetClaimed] = useState<boolean>(
    nextFaucetTime ? differenceInSeconds(nextFaucetTime, new Date()) > 0 : false
  );

  const [nextFaucetClaim, setNextFaucetClaim] = useState<Date | null>(nextFaucetTime);
  const [countdownTimer, setCountdownTimer] = useState<number>(
    nextFaucetTime ? differenceInSeconds(nextFaucetTime, new Date()) : 0
  );

  const claimFaucet = async () => {
    try {
      const response = await post('/user/faucet',{},true);
      const { tokenAmount, nextClaimTime } = response;

      setUser({
        ...user,
        tokenAmount: tokenAmount,
        nextFaucetClaim: nextClaimTime,
      });

      setNextFaucetClaim(new Date(nextClaimTime));
      setCountdownTimer(differenceInSeconds(new Date(nextClaimTime), new Date()));
      setFaucetClaimed(true);

    } catch (error:any) {
      showSnackbar(error.response.data.message, 'error')
    }
  };

  useEffect(() => {
    if (countdownTimer > 0) {
      const timer = setTimeout(() => {
        setCountdownTimer(countdownTimer - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      setFaucetClaimed(false); // Allow user to claim again
    }
  }, [countdownTimer]);

  return (
    <Box
      sx={{
        backgroundColor: 'divider',
        width: '100%',
        borderRadius: '4px',
        border: !faucetClaimed ? '1px solid red' : '',
      }}
    >
      <Box sx={{ py: '0.5rem' }}>
        <Typography
          variant="h4"
          fontWeight="bold"
          textAlign="center"
          sx={{ borderBottom: '1px solid #212121' }}
        >
          Faucet
        </Typography>
      </Box>
      <Box sx={{ px: '1rem' }}>
        <Typography
          variant="body1"
          fontWeight="bold"
          textAlign="center"
          sx={{ mb: '1rem' }}
        >
          
          You can start claiming our token now! <br/> (withdrawals will be live when our token lists)
        </Typography>
        {!faucetClaimed && (
          <Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                pb: '1rem'
              }}
            >
              <Button
                variant="contained"
                onClick={claimFaucet}
                sx={{ height: '5rem', width: '15rem' }}
              >
                Claim now
              </Button>
            </Box>
          </Box>
        )}
        {faucetClaimed && (
          <Box>
            <Typography variant="h6" textAlign="center">
              Claim next faucet in:
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-around',
                alignContent: 'center',
                px: '1rem',
                pb: '1rem'
              }}
            >
              {nextFaucetClaim && <CountdownTimer nextGiveaway={nextFaucetClaim} isTiny={true} fieldsToShow={['minutes', 'seconds']} />}
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
