import React from 'react';
import { Box, Button, Stack, TextField } from '@mui/material';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';
import { emailValidationRegex } from '../../../../constants/regex';
import {
  SettingsUserDetailsFormType
} from '../../../../types/types';
import { useUser } from '../../../../contexts/UserContext';
import { useNetwork } from '../../../../utils/network';
import { useShowSnackbar } from '../../../../utils/snackbar';

export const SettingsUserDetails = () => {
  const { user, setUser } = useUser()
  const { put } = useNetwork();
  const { showSnackbar } = useShowSnackbar();
  const defaultValues: SettingsUserDetailsFormType = {
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    username: user.username,
    walletAddress: user.walletAddress,
  }
  const {
    handleSubmit,
    control,
    formState: { errors },
    watch
  } = useForm({ defaultValues });

  const isFormUnchanged = JSON.stringify(watch()) === JSON.stringify(defaultValues);

  const onSubmit: SubmitHandler<SettingsUserDetailsFormType> = async (data: any) => {
    try {
      const result = await put('/user/details',
        {
          firstName: data.firstName,
          lastName: data.lastName,
          username: data.username,
          //email: data.email
        }, true);

      setUser({
        ...user,
        firstName: data.firstName,
        lastName: data.lastName,
        username: data.username,
        //email: data.email,
      });
      showSnackbar(result.message, 'success');
    } catch (error: any) {
      showSnackbar(error.response.data.message, 'error');
    }
  };

  return (
    <Box
      sx={{
        margin: '0 1rem 0 1rem',
      }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={4}>
          <Controller
            name="firstName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                id="firstName"
                margin="normal"
                required
                fullWidth
                label="First name"
                error={Boolean(errors.firstName)}
                helperText={errors.firstName && 'Please enter your first name'}
                {...field}
              />
            )}
          />
          <Controller
            name="lastName"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                id="lastName"
                margin="normal"
                required
                fullWidth
                label="Last name"
                error={Boolean(errors.lastName)}
                helperText={errors.lastName && 'Please enter your last name'}
                {...field}
              />
            )}
          />
          <Controller
            name="username"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                id="username"
                margin="normal"
                required
                fullWidth
                label="Username"
                error={Boolean(errors.username)}
                helperText={errors.username && 'Please enter your username'}
                {...field}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            rules={{
              required: true,
              pattern: emailValidationRegex
            }}
            render={({ field }) => (
              <TextField
                id="email"
                margin="normal"
                required
                fullWidth
                label="Email"
                autoComplete="email"
                disabled
                error={Boolean(errors.email)}
                helperText={errors.email && 'Please enter a valid email'}
                {...field}
              />
            )}
          />

          <Controller
            name="walletAddress"
            control={control}
            render={({ field }) => (
              <TextField
                id="walletAddress"
                margin="normal"
                fullWidth
                label="Wallet Address"
                disabled
                {...field}
              />
            )}
          />

          <Button variant="contained" type="submit" disabled={isFormUnchanged}>
            Save changes
          </Button>
        </Stack>
      </form>
    </Box>
  );
};
